import Matter from 'matter-js';
import MatterAttractors from 'matter-attractors';

Matter.use(MatterAttractors);

let Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Mouse = Matter.Mouse,
    World = Matter.World,
    Common = Matter.Common,
    Events = Matter.Events,
    Body = Matter.Body,
    Bodies = Matter.Bodies;

let loaded = false;

let engine = Engine.create();
engine.positionIterations = 4;
let world = engine.world;
world.gravity.scale = 0;

let gravityState = true;
let gravityChangingState = false;

const attractionFactorMax = 1e-7;
const attractionFactorMin = 1e-8;
let attractionFactor = attractionFactorMin;

let render = Render.create({
    element: document.getElementById('canvas'),
    engine: engine,
    options: {
        width: window.innerWidth,
        height: window.innerHeight,
        showAngleIndicator: false,
        background: 'transparent',
        enableSleeping: true,
        wireframes: false
    }
});

Render.setPixelRatio(render, window.devicePixelRatio);


const width = render.options.width,
      height = render.options.height,
      wallsThickness = 150,
      wallOptions = {
          isStatic: true,
          render: { visible: false }
      };

World.add(world, [
    Bodies.rectangle(width/2, -wallsThickness/2, width, wallsThickness, wallOptions),
    Bodies.rectangle(width+wallsThickness/2, height/2, wallsThickness, height*3, wallOptions),
    Bodies.rectangle(-wallsThickness/2, height/2, wallsThickness, height*3, wallOptions),
    Bodies.rectangle(width/2, height+wallsThickness/2, width, wallsThickness, wallOptions)
]);

let attractiveBody = Bodies.circle(
        render.options.width / 2,
        render.options.height / 2 - 150,
        40,
        {
            isStatic: true,
            render: {
                fillStyle: '#efefef'
            },
            plugin: {
                attractors: [
                    function(bodyA, bodyB) {
                        const force = {
                            x: (bodyA.position.x - bodyB.position.x) * attractionFactor,
                            y: (bodyA.position.y - bodyB.position.y) * attractionFactor,
                        };
                        // apply force to both bodies
                        //Body.applyForce(bodyA, bodyA.position, Matter.Vector.neg(force));
                        Body.applyForce(bodyB, bodyB.position, force);
                    }
                ]
            }
        }
    );

World.add(world, attractiveBody);
let aboutEl = document.getElementById('about-img');
let aboutBlock = Bodies.rectangle(
        aboutEl.getBoundingClientRect().left,
        aboutEl.getBoundingClientRect().top + 315,
        aboutEl.offsetWidth,
        aboutEl.offsetHeight,
        {
            isStatic: true,
            friction: 0,
            render: { visible: false, fillStyle: '#9f1a1a' },
            chamfer: { radius: Math.floor(aboutEl.offsetWidth/2.5) }
        }
    );

World.add(world, aboutBlock);

const colorsRandom = [
    '#323c43',
    '#323c43',
    '#323c43',
    '#323c43',
    '#3e4951',
    '#3e4951',
    '#3e4951',
    '#49555d',
    '#49555d',
    '#56656e',
    '#ff5127'
];

for (let i = 0; i < 123; i++) {
    let body = Bodies.polygon(
            Common.random(0, render.options.width),
            Common.random(0, render.options.height),
            Common.random(1, 5),
            Common.random() > 0.9 ? Common.random(15, 25) : Common.random(5, 10),
            {
                frictionAir: 0,
                render: {
                    fillStyle: colorsRandom[Math.floor(Common.random(0,colorsRandom.length))]
                },
            }
    );

    World.add(world, body);
}

Events.on(engine, 'afterUpdate', function() {
    if (!mouseWindowPos.x) {
        return;
    }
    Body.translate(attractiveBody, {
        x: (mouseWindowPos.x - attractiveBody.position.x) * 0.05,
        y: (mouseWindowPos.y - attractiveBody.position.y) * 0.05
    });
    let aboutBlockPos = {
        x:  aboutEl.getBoundingClientRect().left + aboutEl.offsetWidth/2,
        y: aboutEl.getBoundingClientRect().top + aboutEl.offsetHeight/2
    };
    Body.set(aboutBlock, 'position', {
        x: aboutBlockPos.x,
        y: aboutBlockPos.y
    });
});

// add mouse control
let mouse = Mouse.create(render.canvas);
render.mouse = mouse;
mouse.element.removeEventListener("mousewheel", mouse.mousewheel);
mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel);

let mouseWindowPos = {
    x: 0,
    y: 0
};

window.addEventListener('mousemove', updateMousePosition, { passive: true });
document.querySelector('body').addEventListener('touchmove', updateMousePosition, { passive: true });
document.querySelector('body').addEventListener('touchstart', updateMousePosition, { passive: true });
document.querySelector('body').addEventListener('touchend', updateMousePosition, { passive: true });


function updateMousePosition(e) {
    if(loaded) {
        mouseWindowPos.x = e.clientX;
        mouseWindowPos.y = e.clientY;
    }
}

window.addEventListener('mousedown', function (e) {
    attractionFactor = attractionFactorMax;
    Body.scale(attractiveBody, 1.25, 1.25);
}, {
    passive: true
});

window.addEventListener('mouseup', function (e) {
    attractionFactor = attractionFactorMin;
    Body.scale(attractiveBody, 0.8, 0.8);
}, {
    passive: true
});

document.getElementById('gravity').addEventListener('click', () => {
    if(gravityState === true) {
        gravityChangingState = true;
        gravityState = false;
        document.body.classList.add('gravity-alert');
        document.getElementById('gravity').classList.add('active');
        attractionFactor = -attractionFactorMax;
        setTimeout(()=> {
            attractionFactor = 0;
            gravityChangingState = false;
        }, 1000);
    } else if(gravityChangingState === false && gravityState === false) {
        attractionFactor = attractionFactorMin;
        gravityState = true;
        document.body.classList.remove('gravity-alert');
        document.getElementById('gravity').classList.remove('active');
    }
});

window.addEventListener('load', function(){
    Render.run(render);
    loaded = true;
}, { passive: true });

let runner = Runner.create();
Runner.run(runner, engine);